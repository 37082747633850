import { dprInsert } from 'public/src/pages/common/customDetailDialog/utils/tools.js'
import { transformImg } from '@shein/common-function'
import { getCurrentInstance } from 'vue'
import _ from 'lodash'

export const useStore = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$store
}

export const isVNode = (node) => {
  return typeof node === 'object' && node.hasOwnProperty('componentOptions')
}

/**
 * 通过 CartId 获取 goods_id
 * @param {Array} carts 购物车商品列表
 * @param {Array, String} filterIds 用于过滤的cartId
 * @returns {Array} goods_id 数组
 */
export const getGoodsIdsByCartItemIds = ({ carts = [], filterIds = [] } = {}) => {
  const result = []
  filterIds = [].concat(filterIds)

  carts.forEach(item => {
    const matched = filterIds.includes(item.id)
    if (matched) result.push(item.product.goods_id)
  })

  return [...new Set(result)]
}

/**
 * 获取用于埋点的 goods_list 参数
 * @param {Array} carts 需要上报的购物车列表
 * @param {Array, String} filterIds 用于过滤的cartId
 * @return {String}
 */
export const getGoodsListForAnalysis = ({ carts = [], filterIds = [] } = {}) => {
  let result = ''
  let exposeList = carts
  filterIds = [].concat(filterIds)
  // 过滤
  if (filterIds && filterIds.length > 0) {
    exposeList = carts.filter(item => filterIds.includes(item.id))
  }

  exposeList.forEach((item, index) => {
    const goodsId = item.product.goods_id
    const skuId = item.product.goods_sn
    const spuId = item.product.productRelationID
    const page = 1
    const operation = 1
    const recMark = ''
    const price = item.product?.feSalePrice?.usdAmount
    const originPrice = item.product?.feOriginalPrice?.usdAmount
    result += `,${goodsId}\`${skuId}\`${spuId}\`${+index + 1}\`${page}\`${operation}\`${recMark}\`\`pri_${price}|pri_${originPrice}\`\`\`mall_${item.mall_code}` // goods_list: goods_id`sku_id`spu_id`坑位`页码`运营位置`流量标识rec_mark
  })

  return result.slice(1)
}


/**
 * 滚动到购物车商品
 * @param {String} id 购物车id
 * @param {Boolean} smooth 是否平滑滚动
 * @param {Number} rate 购物车商品最小展示率[0,1]
 * @param {Boolean} highlightAnimate 是否高亮动画
 */
export const scrollToCartItem = ({ id, smooth = false, rate = 0, timeout = 500, highlightAnimate = false } = {}) => {
  setTimeout(()=>{
    const target = document.querySelector(`.j-cart-item-${id}`)
    console.log(`scroll to .j-cart-item-${id}, and ${target}`)
    if (!target) return

    const { bottom: targetBottom, height: targetHeight, top: targetTop } = target.getBoundingClientRect()
    const cartHeaderHeight = $('.cart-header').height()

    // rate === 0，强制滚动
    if (rate !== 0) {
      const windowHeight = $(window).height()
      const cartCheckoutHeight = $('.j-cart-checkout').height()

      const topVisible = targetBottom - cartHeaderHeight
      const bottomVisible = windowHeight - targetTop - cartCheckoutHeight

      // target * rate 在视口内，不执行滚动
      if (topVisible > (targetHeight * rate) && bottomVisible > (targetHeight * rate)) {
        return console.log(`target more then ${rate * 100}% in viewport`)
      }
    }

    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    const { top, height } = target.getBoundingClientRect()
    const elCenter = top + height / 2
    const center = window.innerHeight / 2
    const scrollDistance = scrollTop - (center - elCenter)
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style
    if (supportsNativeSmoothScroll && smooth && window.scrollTo) {
      window.scrollTo({ top: scrollDistance, behavior: 'smooth' })
    } else {
      $(window).scrollTop(scrollDistance)
    }
    if(highlightAnimate) {
      const cartItemContentDom = target?.querySelector('.mshe-item')
      if(cartItemContentDom) {
        const excuteAnimate = _.debounce(function() {
          triggerHighlightAnimation(cartItemContentDom, [cancelListener])
        }, 300)
        function cancelListener() {
          window.removeEventListener('scroll', excuteAnimate)
        }
        window.addEventListener('scroll', excuteAnimate)
      } else {
        console.log('.mshe-item not found')
      }
    }
  }, timeout)
}

export function triggerHighlightAnimation(element, callbacks) {
  if (!element) return
  callbacks?.forEach(cb => cb())
  // 添加高亮动画类
  element.classList.add('highlight-animate')
  // 让浏览器有时间渲染动画，然后移除类以便之后可以重新触发动画
  setTimeout(() => {
    element.classList.remove('highlight-animate')
  }, 800) // 动画总时间是 800ms
}

/**
 * 判断购物车中是否存在某促销活动
 * @param {Array} carts 购物车商品
 * @param {Array} activityTypeIds 促销活动typeId
 * @return activity_method 0存在 1不存在
 */
export const judgeActivityIsExist = (carts = [], activityTypeIds = []) => {
  let activity_method = 1
  carts.forEach(item => {
    item?.product?.product_promotion_info?.forEach(info => {
      if (activityTypeIds.includes(+info.type_id)) {
        activity_method = 0
      }
    })
  })
  return activity_method
}

/**
 * 购物车中促销活动强调 滚动、颜色变红、抖动
 * @param {Array} id 活动id
 * @param {Object} options 强调
 */
export const emphasizePromotionHandle = (id, options = {}) => {
  const { shake = false, changeColor = false, scroll = false } = options
  const containerDom = $(`.j-promotion-header-${id}`).get(0)
  if (!containerDom) return

  // const nowScrollTop = $(window).scrollTop()
  // const containerTop = containerDom.getBoundingClientRect().top
  // const cartHeaderHeight = $('.j-cart-header').height()
  // const cartHecaderHeight = $('.sticky-block').height()

  // 是否滚动到标题处
  if (scroll) {
    const { top, height } = $(`.j-promotion-header-${id}`).offset()
    const offsetCenter = ($('body').offset()?.height || 0) / 2
    const needScroll = (top + height) <= offsetCenter ? false : true
    if(needScroll){
      const hdHeight = $('.j-cart-header').offset()?.height || 0
      // 居中展示
      let scrollTopHeight = top - hdHeight - (offsetCenter + height) / 2
      // let scrollTopHeight = nowScrollTop + containerTop - cartHeaderHeight
      // $('html').scrollTop(top - hdHeight - (offsetCenter + height) / 2)
      if (window.scrollTo) {
        window.scrollTo({ top: scrollTopHeight, behavior: 'smooth' })
      } else {
        $(window).scrollTop(scrollTopHeight)
      }
    } else {
      if (window.scrollTo) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        $(window).scrollTop(0)
      }
    }
  }

  // 颜色变红
  if (changeColor) {
    const $target = $(containerDom).find('.cart_item-tip')
    $target.addClass('cart_tip-red')
  }

  // 是否抖动
  if (shake) {
    const $target = $(containerDom).find('.cart_item-banner')
    $target.addClass('animated animate-shake')
    setTimeout(() => {
      $target.removeClass('animated animate-shake')
    }, 1000)
  }
}

/**
 * 购物车中促销活动强调 滚动、颜色变红、抖动
 * @param {Array} id 活动id
 * @param {Object} options 强调
 */
export const cartEmphasizePromotionAll = (ids = [], options = {}) => {
  ids.forEach((id, index) => {
    if (index > 0) options.scroll = false // 只滚动到第一个活动
    emphasizePromotionHandle(id, options)
  })
}

export const handleRangeText = (allDiff = {}, toast) => {
  const { type, value, value_amount } = allDiff
  const difference = type == 1 ? value : (value_amount?.amountWithSymbol || '')
  return toast ? difference : `<span class="add-buys-price">${difference}</span>`
}


const flashSaleAndDiscountLimitHandle = (goods = [])=>{
  let obj = {
    orderTotalProductSize: 0,
    skcLimitNum: {}
  }
  goods.forEach(item => {
    obj.orderTotalProductSize += item.quantity
    // 同类商品skc不同尺码sku加起来的数量
    obj.skcLimitNum[item.product.goods_sn] = (obj.skcLimitNum[item.product.goods_sn] || 0) + item.quantity
  })
  return obj
}

/**
 *
 * @param {object} product 购物车商品信息 不传则计算总数skc
 * @param {object} itemPromotionGroupInfo 商品所在促销组信息
 * @param {boolean} isOver 判断是超出还是满足或超出 默认false满足或超出 点击checkout校验时传true
 * limit_code:0超限拦截 1超限按原价
 * @param {boolean} isDetail 是否返回计数详情, 用于兼容旧的调用方式, 默认为false, 传true时, 将以对象的形式返回计数详情及超限判断结果
 * @return {*|boolean}
 */
export const handleOrderOrSingleIsMeetOrOver = (product = {}, itemPromotionGroupInfo = {}, isOver, isDetail = false) => {
  let result = 0
  let flashSaleAndDiscountLimitHandleInfo
  if(([10].includes(+itemPromotionGroupInfo.type_id) && itemPromotionGroupInfo.limit_mode != 1) || [11].includes(+itemPromotionGroupInfo.type_id)){
    let single_over = false
    let order_over = false
    let proGroupList = itemPromotionGroupInfo.list?.filter(item => item.is_checked) || []
    flashSaleAndDiscountLimitHandleInfo = flashSaleAndDiscountLimitHandle(proGroupList)
    let { member, single, order } = itemPromotionGroupInfo.cart_limit || {}
    if(+single){
      if(!product) {
        let flashSaleSkcLimitNum =  Object.values(flashSaleAndDiscountLimitHandleInfo.skcLimitNum)
        single_over = flashSaleSkcLimitNum.find(item => item > single) ? true : false
      } else {
        single_over = isOver ? flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] > single : flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] >= single
      }
    }
    let cart_limit_order = 0
    if(+member && +order){
      cart_limit_order = Math.min(+member, +order)
    }else if( +order || +member ){
      cart_limit_order = +member || +order
    }
    if(cart_limit_order) {
      order_over = isOver ? flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize > cart_limit_order : flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize >= cart_limit_order
    }
    /**
     * 将四种组合状态转换为二进制数进而转换为0-3的状态码 result
     * 0: 未超限
     * 1: 单品超限
     * 2: 整单超限, 总数超限
     * 3: 单品超限且整单超限
     * 此处状态码与transformPromotionTip中的content.tip.list索引对应
     */
    result = parseInt(`${+Boolean(order_over)}${+Boolean(single_over)}`, 2)
  }
  if([24, 30].includes(+itemPromotionGroupInfo.type_id)){
    let proGroupList = itemPromotionGroupInfo.list?.filter(item => item.is_checked) || []
    flashSaleAndDiscountLimitHandleInfo = flashSaleAndDiscountLimitHandle(proGroupList, true)
    let { single, order, member } = itemPromotionGroupInfo.cart_limit || {}
    // 同类商品不同尺码加起来的数量超过该商品的限购数时
    if(+single){
      const single_over = isOver ? flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] > single : flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] >= single
      single_over && (result = 1)
    }
    let cart_limit_order = 0
    if(+member && +order){
      cart_limit_order = Math.min(+member, +order)
    }else if( +order || +member ){
      cart_limit_order = +member || +order
    }
    if(cart_limit_order){
      const order_over = isOver ? flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize > cart_limit_order : flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize >= cart_limit_order
      order_over && (result = 2)
    }
  }
  if(isDetail) return { result, flashSaleAndDiscountLimitHandleInfo }
  else return result
}

// 删除动画
export const animationDelete = ($el, cb) =>{
  if (!$el) return
  const h = $el.clientHeight || 0
  $el.style.overflow = 'hidden'
  $el.style.opacity = 0
  // 默认 60fps/s
  const fps = 60
  // 花费时间
  const times = 0.5
  const distance = Math.ceil(h / (fps * times) )
  let transitionItem  = ($el, h, distance) => {
    window.requestAnimationFrame(() => {
      let vh = h - distance
      vh = vh < 0 ? 0 : vh
      $el.style.height = `${vh}px`
      if (vh > 0) return transitionItem($el, vh, distance)
      return cb()
    })
  }
  transitionItem($el, h, distance)
}

/**
 * body元素overflow属性变化
 * @param show
 * @param type 场景：
 *   quickAdd(默认)：为附属品弹窗，出现快捷加车时；
 *   promotionHub：购物车底部活动展开时
 *   可继续补充其他需求场景
 */
export const handleBodyOverflow = (show, type = 'quickAdd') => {
  if (type == 'quickAdd' && $('body').css('position') == 'fixed'){
    $('body').css('overflow', show ? '' : 'hidden')
  }
  // bug10013-购物车底部活动展开时，要取消body的滚动效果
  if (type == 'promotionHub') {
    $('body').css('overflow', show ? 'hidden' : '')
  }
}

/**
 *
 * @param [String] amountWithSymbol 带币种金额
 * @return string
 */
export const amountWithSymbolTransform = (amountWithSymbol = '', currencyDecPointSymbol = '') => {
  if ( !amountWithSymbol ) return ''
  if(!currencyDecPointSymbol && typeof window != 'undefined') {
    let { currency, currencies, lang, appLanguage } = gbCommonInfo
    let currencyObj = currencies?.[lang]?.[currency] || {}
    let { separator_multi_resp_list = [] } = currencyObj || {}
    let existSeparator = separator_multi_resp_list?.filter(item => item?.language_flag == appLanguage)
    if (existSeparator?.length) {
      currencyDecPointSymbol = existSeparator?.[0]?.dec_point_symbol || '.'
    } else {
      currencyDecPointSymbol = currencyObj?.dec_point_symbol || '.'
    }
  }
  let hasDecPointSymbol = amountWithSymbol.includes(currencyDecPointSymbol)
  let reg = hasDecPointSymbol ? new RegExp(`^(\\D*)(.*?)([\\${currencyDecPointSymbol}])(\\d*?)(\\D*)$`, 'g') : new RegExp(`^(\\D*)(.*?)(\\D*)$`, 'g')
  let result = amountWithSymbol.replace(reg, function () {
    let currencyStr = RegExp.$1 || (hasDecPointSymbol ? RegExp.$5 : RegExp.$3) ? `<span class="price-currency">${ RegExp.$1 || (hasDecPointSymbol ? RegExp.$5 : RegExp.$3) }</span>` : ''
    let str = `<span class="price-amount-decimal">`
    str = str + (RegExp.$2 ? `<span class="price-amount">${ RegExp.$2 }</span>` : '')
    str = str + (hasDecPointSymbol && RegExp.$3 ? `<span>${ RegExp.$3 }</span>` : '')
    str = str + (RegExp.$4 ? `<span class="price-decimal">${ RegExp.$4 }</span>` : '')
    str = str + `</span>`
    return RegExp.$1 ? currencyStr + str : str + currencyStr
  })
  return result
}

export function getCartItemIndex({ item, id, cartsId } = {}) {
  id = id || (item ? item.id : '')
  if (!id) return -1
  if (typeof cartsId !== 'undefined') {
    return cartsId.indexOf(id)
  } 
  if (typeof window === 'undefined' || !window._gb_app_) return -1
  cartsId = window._gb_app_.$store.state.cart.cartsId || []
  return cartsId.indexOf(id)
}

/**
 * 和找相似和重选有关: 购物车多处用到了, 统一先放到这
 * @param {Object} item 购物车商品行
 * @param {Boolean} isPlatForm 是否平台化mall
 * @return {Boolean}
 */
export const isValidCartItem = (item, isPlatform = false) => {

  if(item.status == -1 && !isPlatform) { // 当前单mall站点没有该mall商品 直接失效展示'找相似'
    return false
  }
  if(item.status == 2 && [0, 2, 3, 4].includes(+item.promotion_status)){
    return false
  }

  const isNoRelated = Array.isArray(item.relatedProductIds) ? !item.relatedProductIds.length : !item.relatedProductIds  // 无关联商品
  if(!isNoRelated) {
    return true
  }

  if (isPlatform && item.mall_code_list?.length > 0) { // 其他mall可售
    return true
  }
  const isNoEnough = item.product.stock == 0 && +item.realTimeInventory < +item.quantity // 无关联商品且商品总库存为0
  const isNoOnSale = item.product.is_on_sale == 0 // 无关联商品且商品已下架
  const noMainSaleAttrs = item.product.sku_sale_attr && item.product.sku_sale_attr.filter(item => !item.is_main && item.attr_value_id) || [] // 新sku属性结构 过滤掉主属性后的尺码 attr_value_id ==0为无尺码 反之为有尺码
  let oldSingleOrOneSize = (!item.attr || item.attr?.attr_value_en == 'one-size' || item.attr?.attr_value_id === 0 )
  const isNoOtherAttrs = (oldSingleOrOneSize || (item.product.sku_code && !noMainSaleAttrs.length)) // 无关联商品且商品无其他尺码

  return !(isNoEnough || isNoOnSale || isNoOtherAttrs)
}

export function getBrowserName() {
  let userAgent = navigator.userAgent
  if (userAgent.includes('Firefox')) {
    // "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
    return 'Firefox'
  } else if (userAgent.includes('SamsungBrowser')) {
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
    return 'SamsungBrowser'
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
    return 'Opera'
  } else if (userAgent.includes('Edge')) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    return 'Microsoft Edge (Legacy)'
  } else if (userAgent.includes('Edg')) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
    return 'Microsoft Edge (Chromium)'
  } else if (userAgent.includes('Chrome')) {
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
    return 'Google Chrome or Chromium'
  } else if (userAgent.includes('Safari')) {
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
    return 'Apple Safari'
  } else {
    return 'unknown'
  }
}

export function getMainImageUrl({ item, IS_RW, customServiceImgDomains, thirdPartyServiceImgDomains }) {
  const isCustomGood = Boolean(Object.keys(item.product?.customization_info || {}).length)
  if(isCustomGood && !IS_RW) {
    const effectUrl = dprInsert({ img: item.product?.customization_info?.preview?.effects?.[0] || item.product?.goods_img || '', customServiceImgDomains, thirdPartyServiceImgDomains }) // effects 为空则不做替换
    return transformImg({ img: effectUrl })
  } else {
    return transformImg({ img: item.product?.goods_img })
  }
}

export const getAddOnItemStatus = (promotion, cartList = [], soldoutIds = []) =>{
  const { type_id, promotion_id } = promotion
  const promotionProductTypeMap = {
    4: '1',
    2: '1',
    28: '1',
    22: '2',
    13: '2',
  }

  let onSaleList = cartList.filter(item => soldoutIds.indexOf(item.id) == -1)
  const item = onSaleList.find(item => 
    item.product?.product_promotion_info?.some(p => 
      p.promotion_product_type == promotionProductTypeMap[type_id] && p.promotion_id == promotion_id
    )
  )

  return {
    isPicked: !!item,
    isChecked: !!item?.is_checked,
  }
}

/**
 * 乱序数组 Fisher–Yates
 * @param {Array}
 * @return {Array}
 */
export const shuffleArr = (arr) => {
  const len = arr.length - 1
  for(let i = len; i > 0; i--){
    const j = Math.floor(Math.random() * i)
    const temp = arr[i]
    arr[i] = arr[j]
    arr[j] = temp
  }
  return arr
}

/**
 * 处理价格动画
 * @param {Object} newTotalPrice 新的价格对象
 * @param {Object} oldTotalPrice 旧的价格对象
 * @param {Function} callback 回调函数
 * @description 代码来自 actions.js 内部
 */
export const handleTotalPriceAnimate = (newTotalPrice, oldTotalPrice, callback) => {
  if (typeof window === 'undefined') {
    callback(newTotalPrice)
    return
  }
  const currencyRule = window.getCurrencyRule()
  const { decimal_place } = currencyRule
  const isAdd = +newTotalPrice.amount > +oldTotalPrice.amount
  // 完成动画花费的时长
  const duration = 300
  // 一帧的时长
  const TIME_PER_FRAME = 16.7
  // 动画帧数
  const frameCount = duration / TIME_PER_FRAME
  const priceDiff = newTotalPrice.amount - oldTotalPrice.amount
  // 每一帧的价格步长
  const priceStep = +(priceDiff / frameCount).toFixed(decimal_place)
  let currentPrice = +oldTotalPrice.amount
  let isAnimate = true
  let timer = setInterval(() => {
    if (isAnimate) {
      // 防止步长为0时导致死循环
      if (priceStep == 0) {
        currentPrice = +newTotalPrice.amount
        oldTotalPrice = newTotalPrice
      } else {
        currentPrice += priceStep
        if (isAdd) {
          if (currentPrice >= +newTotalPrice.amount) {
            currentPrice = +newTotalPrice.amount
            oldTotalPrice = newTotalPrice
          } else {
            oldTotalPrice = { ...oldTotalPrice, amount: currentPrice, amountWithSymbol: window.GB_transform_price_symbol(currentPrice, '', currencyRule) }
          }
        } else {
          if (currentPrice <= +newTotalPrice.amount) {
            currentPrice = +newTotalPrice.amount
            oldTotalPrice = newTotalPrice
          } else {
            oldTotalPrice = { ...oldTotalPrice, amount: currentPrice, amountWithSymbol: window.GB_transform_price_symbol(currentPrice, '', currencyRule) }
          }
        }
      }
    }
    callback && callback(oldTotalPrice) 
    if (currentPrice == newTotalPrice.amount) {
      clearInterval(timer)
      timer = null
    }
  }, TIME_PER_FRAME)
}

/* 判断当前活动是否是新人返券活动
* @param {*} promotionInfo 活动信息
*/
export const isNewUserReturnCoupon = (promotionInfo) => {
  if (typeof window === 'undefined') return false
  const { NEW_USER_COUPON_TYPE_ID = 0 } = gbCommonInfo || {}
  return promotionInfo?.typeId == 21 && promotionInfo?.coupons?.length && promotionInfo?.coupons?.every(item => item?.type_id == NEW_USER_COUPON_TYPE_ID)
}

/**
 * 判断元素是否有部分存在于可视区域
 */
export const isElementPartiallyInViewport = (element) => {
  if (!element) return
  const rect = element.getBoundingClientRect()
  const winH = window.innerHeight || document.documentElement.clientHeight
  const winW = window.innerWidth || document.documentElement.clientWidth

  const vertInView = rect.top <= winH && rect.top + rect.height >= 0
  const horInView = rect.left <= winW && rect.left + rect.width >= 0

  return (vertInView && horInView)
}

/**
 * 判断是否30天低价
 */
export const isThirtyLowestPrice = (tsp, item) => {
  // 取商品当前价格为 y， 如果有特价，则取特价、如果没有特价，则取原价；
  // 当商品命中新人闪购、特殊闪购、付费会员、S3会员，券后价，默认取原价为 y；
  const toArr = arr => Array.isArray(arr) ? arr : [arr]
  const promotionInfo = toArr(item?.product?.product_promotion_info || item?.promotionInfo || {}) 
  const isHit = type => promotionInfo.find(item => item.typeId == type || item.type_id == type)
  const isVip = isHit('29') // 付费会员
  const isS3 = isHit('12') // S3 会员
  const flashPromotion = isHit('10')
  const isFlash = flashPromotion && [2, 3].includes(item.flashType || flashPromotion.flashType || flashPromotion.flash_type ) // 闪购

  // 取值逻辑
  // 购物车： 原价 product?.retailPrice，销售价 unitPrice 
  // 其他地方: 原价 retail_price 或 retailPrice，销售价 sale_price 或 salePrice
  const amount = isFlash || isVip || isS3
    ? (item.product?.feOriginalPrice?.amount || item.retail_price?.amount || item.retailPrice?.amount)
    : (item.unit_price_before_coupon?.amount || item.sale_price?.amount || item.salePrice?.amount) 
  
  const d1 = tsp?.['4018'] || ''
  const x1 = +(tsp?.['3864'] || ''),
        z1 = +(tsp?.['4015'] || '')
  const x2 = +(tsp?.['3865'] || ''),
  z2 = +(tsp?.['4016'] || '')
  const x3 = +(tsp?.['3866'] || ''),
  z3 = +(tsp?.['4017'] || '')

  const result = {
    30: amount <= x1 && amount < z1 && d1 > 1 ? 30 : false,
    60: amount <= x2 && amount < z2 && d1 > 1 ? 60 : false,
    90: amount <= x3 && amount < z3 && d1 > 1 ? 90 : false,
  }
  // 过去30天最低价：3864 | 过去30天最高价：4015 | 是否调价: 4018
  return result
}

export const getCountryId = () => {
  let addressCookie = localStorage.getItem('addressCookie')
  let countryId = ''
  try {
    addressCookie = JSON.parse(addressCookie)
    countryId = addressCookie?.countryId ?? ''
  } catch {
    return countryId
  } 
  return countryId
}

/**
 * 获取商品销售属性文案
 */
export const getItemSaleText = ({ defaultlocalsize, skuSaleAttr, sizeRuleList, userLocalSizeCountry }) => {
  const formatStrategy = {
    old: {
      cartSize: ({ defaultAttr, localAttr }) => {
        if (localAttr) {
          return `${localAttr}(${defaultAttr})`
        }
        return defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        if (localAttr) {
          return `${localAttr}(${defaultAttr})`
        }
        return defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
    new: {
      cartSize: ({ defaultAttr }) => {
        return defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        if (localAttr) {
          return `${localAttr}(${defaultAttr})`
        }
        return defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
    local_size_no_default: {
      cartSize: ({ defaultAttr, localAttr }) => {
        return localAttr || defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        return localAttr || defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
    default_size_no_default: {
      cartSize: ({ defaultAttr }) => {
        return defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        return localAttr || defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
  }

  const executeStrategy = () => {
    const strategy = formatStrategy[defaultlocalsize] ?? formatStrategy.old
    const handleStrategy = userLocalSizeCountry ? userLocalSizeCountry === 'default' ? strategy.defaultSize : strategy.localSize : strategy.cartSize
    const getSizeMap = (attrSize = []) => attrSize?.reduce((acc, item) => ({ ...acc, [item.name]: item }), {})
    const attrSizeMap = getSizeMap(sizeRuleList)
    return skuSaleAttr?.map(item => {
      const text = item.is_main != 1
        ? handleStrategy({
          defaultAttr: item.attr_value_name,
          localAttr: attrSizeMap[item.attr_value_name_en]?.correspond,
        })
        : item.attr_value_name
      return text
    }) ?? []
  }

  return executeStrategy()
}

/**
 * 获取折扣文案
 */
export const getDiscountText = (lang, GB_cssRight, discount) => {
  if (discount) {
    const symbol = lang != 'il' ? '-' : ''
    return GB_cssRight ? `%${discount}${symbol}` : `${symbol}${discount}%`
  }
  return ''
}
